import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import Block from './Block'
import CustomerLogos from './CustomerLogos'
import Layout from './Layout'
import { YoutubeVideo } from './YoutubeVideo'

interface Props {
  header: ReactNode
  campaign: { value: string }
}

const WebinarPage = ({ header }: Props) => {
  return (
    <Layout
      justifyCenter
      title="How to scale engineering with data [on-demand webinar]"
    >
      {header}
      <Block type="padding-top-medium">
        <Container>
          <div className="columns">
            <div className="col">
              <Intro />
            </div>
            <div className="col">
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  paddingBottom: '56.25%',
                  marginTop: '24px',
                  height: '0',
                }}
              >
                <YoutubeVideo videoId="PqqjqXZDA2E" />
              </div>
            </div>
          </div>
        </Container>
      </Block>
      <FeaturedBlogArticles
        heading="More from Swarmia"
        articles={[
          '/blog/measuring-software-development-productivity/',
          '/blog/dora-metrics/',
          '/blog/issue-cycle-time/',
        ]}
      />
      <Block type="padding-bottom-medium padding-top-none">
        <CustomerLogos />
      </Block>
    </Layout>
  )
}

const Intro = () => {
  return (
    <IntroWrapper>
      <h2
        style={{
          marginTop: '1.5rem',
        }}
      >
        Research-backed advice for scaling software development
      </h2>
      <div className="text-tight">
        <p>
          Watch this on-demand webinar to get{' '}
          <b>
            practical advice on how to use software development analytics to
            ship better software faster.
          </b>
        </p>
        <p>
          In this session, we walk through three typical, real-life challenges
          growing software teams face — and discuss how Swarmia can help solve
          those issues.
        </p>
      </div>
    </IntroWrapper>
  )
}

const IntroWrapper = styled.div`
  max-width: 640px;
  margin: 0 auto;
`

const Container = styled.div`
  .columns {
    display: flex;
    width: 100%;
    margin: auto;
    max-width: 1280px;
  }
  .col {
    padding: 0 32px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  margin-bottom: 12px;

  & h3 {
    opacity: 0.5;
    font-weight: 500;
  }

  // Show only one column if mobile
  @media screen and (max-width: 981px) {
    .columns {
      flex-direction: column;
      .col {
        width: 100%;
      }
    }
  }
`

export default WebinarPage
